<template>
  <div class="pt-5">
    <v-container v-if="render_now">
      <v-row>
        <v-col :cols="screenSize > 960 ? '' : '12'">
          <v-row style="max-height: 80px">
            <div style="font-size: 25px" class="mt-4 mb-6">
              <v-btn
                class="mr-2"
                color="black"
                icon
                @click="
                  renderNow = false;
                  $router.push('/teachers/sms-email');
                "
              >
                <v-icon size="36">arrow_back</v-icon>
              </v-btn>
              {{ elementTypeCased }} Details
            </div>
          </v-row>
          <v-row>
            <v-card
              :width="screenSize < 601 ? '100%' : '90%'"
              :class="screenSize < 601 ? 'pl-4 pt-2' : 'pl-4 pt-2 ml-4'"
              flat
            >
              <v-row
                no-gutters
                style="
                  flex-direction: row;
                  justify-content: flex-start;
                  padding-top: 14px;
                "
              >
                <v-chip
                  v-for="(courseName, i) in isSuccessful[3]"
                  :key="i"
                  color="#D4D4D4"
                  label
                  class="ma-1"
                  >{{ courseName }}
                </v-chip>
              </v-row>

              <v-row>
                <v-col
                  :class="screenSize < 601 ? 'pl-0' : 'pl-2'"
                  style="font-size: 12px"
                  cols="auto"
                >
                  <v-row :class="screenSize > 601 ? 'ml-3 mt-0' : 'mt-3 ml-3'">
                    <div>{{ new Date(dateTimeFormat).toDateString() }}</div>
                    <div class="ml-4">
                      {{
                        new Date(dateTimeFormat).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true
                        })
                      }}
                    </div>
                  </v-row>
                </v-col>
                <v-col style="padding-top: 0px; margin-left: 3%" cols="auto">
                  <v-row style="font-size: 14px">
                    <v-checkbox
                      v-model="detailsResponse.is_send_to_student"
                      style="pointer-events: none"
                      color="b4b4b4"
                    ></v-checkbox>
                    <div class="mt-5">Students</div>
                    <v-checkbox
                      v-model="detailsResponse.is_send_to_guardian"
                      style="pointer-events: none"
                      color="b4b4b4"
                      class="ml-4"
                    ></v-checkbox>
                    <div class="mt-5">Guardians</div>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  :class="screenSize > 1279 ? 'div-font-bg' : 'div-font-sm'"
                >
                  <v-row :class="screenSize < 601 ? 'ml-0' : 'ml-3'">
                    <div>Total Sent {{ elementTypeCased }}:</div>
                    <div class="ml-2">{{ sentTo.length }}</div>
                  </v-row>
                </v-col>
                <v-col
                  :class="screenSize > 1279 ? 'div-font-bg' : 'div-font-sm'"
                >
                  <v-row :class="screenSize < 601 ? 'ml-0' : 'ml-3'">
                    <div>Batch(s):</div>
                    <div class="ml-2">{{ isSuccessful[2] }}</div>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  :class="screenSize > 1279 ? 'div-font-bg' : 'div-font-sm'"
                >
                  <v-row
                    style="color: #0099dc"
                    :class="screenSize < 601 ? 'ml-0' : 'ml-3'"
                  >
                    <div>Successful {{ elementTypeCased }}:</div>
                    <div class="ml-2">{{ isSuccessful[0] }}</div>
                  </v-row>
                </v-col>
                <v-col
                  :class="screenSize > 1279 ? 'div-font-bg' : 'div-font-sm'"
                >
                  <v-row
                    class="decline--text"
                    :class="screenSize < 601 ? 'ml-0' : 'ml-3'"
                  >
                    <div>Failed {{ elementTypeCased }}:</div>
                    <div class="ml-2">{{ isSuccessful[1] }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-row>

          <div v-if="elementType === 'email'" style="margin-top: 6%">
            <span style="font-size: 18px">Subject</span>
            <div
              class="bg-white pa-6 mt-4 border-1-2 border-solid border-default rounded xl:text-xs lg:text-xs md:text-xs sm:text-xs"
              style="width: 95%"
              v-html="detailsResponse.subject"
            ></div>
          </div>

          <div style="margin-top: 6%">
            <span style="font-size: 18px; font-weight: 500">Message</span>
            <div
              class="bg-white pa-6 mt-4 border-1-2 border-solid border-default rounded xl:text-xs lg:text-xs md:text-xs sm:text-xs"
              style="width: 95%"
              v-html="detailsResponse.message_body"
            ></div>
          </div>
        </v-col>

        <v-col>
          <v-tabs
            v-model="tab"
            style="display: flow-root"
            class="pb-4"
            hide-slider
            background-color="#f3f7ff"
            color="#f3f7ff"
          >
            <v-tab style="background-color: #f3f7ff">
              {{ elementTypeCased }} log
            </v-tab>
            <!--            the edit is a temporary solution-->
            <!--            <v-tab style="background-color: #f3f7ff">-->
            <!--              <v-btn-->
            <!--                :outlined="tab !== 0"-->
            <!--                dark-->
            <!--                color="#0099dc"-->
            <!--                elevation="1"-->
            <!--                :class="tab === 0 ? 'white&#45;&#45;text' : ''"-->
            <!--                >SUCCESSFUL-->
            <!--              </v-btn>-->
            <!--            </v-tab>-->
            <!--            <v-tab style="background-color: #f3f7ff">-->
            <!--              <v-btn-->
            <!--                :outlined="tab !== 1"-->
            <!--                color="#FC6222"-->
            <!--                elevation="1"-->
            <!--                :class="tab === 1 ? 'white&#45;&#45;text' : ''"-->
            <!--                >FAILED-->
            <!--              </v-btn>-->
            <!--            </v-tab>-->
          </v-tabs>
          <v-tabs-items v-model="tab" style="max-height: 100%">
            <v-tab-item
              style="
                background-color: #f3f7ff;
                max-height: 80vh;
                width: 100%;
                overflow-y: scroll;
              "
            >
              <div v-for="(sent, i) in sentTo" :key="i">
                <message-history-card
                  :message-type="elementType"
                  :message="sent"
                  :student="detailsResponse.is_send_to_student"
                  :guardian="detailsResponse.is_send_to_guardian"
                ></message-history-card>
              </div>
            </v-tab-item>
            <!--            the edit is a temporary solution-->
            <!--            <v-tab-item-->
            <!--              style="-->
            <!--                background-color: #f3f7ff;-->
            <!--                max-height: 80vh;-->
            <!--                overflow-y: scroll;-->
            <!--              "-->
            <!--            >-->
            <!--              <div v-for="(sent, i) in sentTo" :key="i">-->
            <!--                <message-history-card-->
            <!--                  v-if="!hasSuccessfullySentMessage(sent)"-->
            <!--                  :message-type="elementType"-->
            <!--                  :message="sent"-->
            <!--                  color="orange"-->
            <!--                ></message-history-card>-->
            <!--              </div>-->
            <!--            </v-tab-item>-->
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { firstLetterUpperCase } from "@ecf/global/helpers";
import MessageHistoryCard from "@ecf/modules/sms-and-email/MessageHistoryCard";
import ContactNotificationService from "#ecf/sms-and-email/services/ContactNotificationService.js";

export default {
  name: "SmsAndEmailDetails",
  components: {
    MessageHistoryCard
  },
  props: {
    elementType: {
      required: true
    }
  },
  data() {
    return {
      render_now: false,
      detailsResponse: {},
      sentTo: [],
      tab: 0
    };
  },
  computed: {
    isSuccessful() {
      let success = 0;
      let fail = 0;
      let batchCount = 0;
      let course_name = [];
      let is_send_to_student = this.detailsResponse.is_send_to_student;
      let is_send_to_guardian = this.detailsResponse.is_send_to_guardian;
      for (let index = 0; index < this.sentTo.length; index++) {
        if (is_send_to_student) {
          if (typeof this.sentTo[index].batch_name === "string") {
            batchCount = 1;
          } else {
            batchCount = this.sentTo[index].batch_name.length;
          }
          if (this.sentTo[index].course_name) {
            course_name.push(this.sentTo[index].course_name);
          }
          if (this.sentTo[index].message_delivery_status === "success") {
            success += 1;
          } else {
            fail += 1;
          }
        }
        if (is_send_to_guardian) {
          this.sentTo[index].guardian_info?.forEach(x => {
            x.message_delivery_status === "success"
              ? (success += 1)
              : (fail += 1);
          });
        }
      }
      return [success, fail, batchCount, course_name];
    },
    elementTypeCased() {
      return this.elementType === "email"
        ? firstLetterUpperCase(this.elementType)
        : this.elementType.toUpperCase();
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },

    dateTimeFormat() {
      let localdateTime = this.detailsResponse.date_time;
      let splitedDateTime = localdateTime.split("-");
      let newDate = "";
      splitedDateTime.forEach(myFunction);

      function myFunction(value, index, array) {
        if (index === 3) {
          newDate += "T";
        } else if (index > 3) {
          newDate += ":";
        } else {
          if (index != 0) {
            newDate += "-";
          }
        }
        newDate += value;
      }

      return newDate;
    }
  },
  async created() {
    this.detailsResponse = await ContactNotificationService.retriveDetails(
      this.$store.state.user.user.last_partner_code,
      this.$route.params.message_id
    );
    this.sentTo = this.sentTo.concat(this.detailsResponse.recipients_info);
    this.render_now = true;
  },
  methods: {
    hasSuccessfullySentMessage(item) {
      let is_send_to_student = this.detailsResponse.is_send_to_student;
      let is_send_to_guardian = this.detailsResponse.is_send_to_guardian;
      let outcome = false;
      if (is_send_to_student)
        outcome = outcome || item.message_delivery_status === "success";
      if (is_send_to_guardian) {
        item.guardian_info.forEach(x => {
          outcome = outcome || x.message_delivery_status === "success";
        });
      }
      return outcome;
    }
  }
};
</script>

<style lang="scss">
.div-font-bg {
  font-size: 16px;
  padding-top: 0px;
}

.div-font-sm {
  font-size: 14px;
  padding-top: 0px;
}

.v-slide-group__wrapper {
  display: flow-root;
}

@media screen and (max-width: 900px) {
  .container {
    display: flex;
    flex-flow: column-reverse;
  }
}
</style>
