<template>
  <v-card
    :style="
      screenSize < 1350
        ? 'width: 100%; min-height: 90px'
        : 'width: 86%; min-height: 90px'
    "
    :class="screenSize < 601 ? 'mt-4 pl-3 pt-3' : 'mt-4 pl-5 pt-3'"
    flat
    outlined
  >
    <v-row no-gutters style="height: 52%">
      <v-col
        class="pa-0 xl:text-xs lg:text-xs md:text-xs sm:text-xs"
        :cols="screenSize < 601 ? '12' : '5'"
      >
        <v-row no-gutters class="ml-0">
          <!-- <div class="inner-circle mr-2" style="">
            {{ getImageFromTitle(message.name) }}
          </div> -->
          <v-avatar color="primary" size="32"
            ><span class="white--text">
              {{ getImageFromTitle(message.student_name) }}
            </span></v-avatar
          >

          <div class="mt-2 ml-2">{{ message.student_name }}</div>
        </v-row>
      </v-col>
      <v-col
        :style="
          screenSize < 601
            ? 'padding-top: 8px; margin-left: 3px'
            : 'padding-top: 8px; margin-left: 6%'
        "
      >
        <v-row no-gutters style="font-size: 14px">
          <img src="../../global/assets/img/icons/batch_icon.svg" />
          <div class="ml-2">{{ message.batch_name }}</div>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters style="font-size: 12px" class="pb-2 pt-2">
      <v-col
        :style="
          screenSize < 601
            ? 'padding-top: 0px; max-width: 78%'
            : 'padding-top: 0px; max-width: 48%'
        "
      >
        <v-row no-gutters>
          <img :class="classes.student.icon" :src="smsOrEmailIcon" />
          <span class="mt-1 ml-1" :class="classes.student.text">{{
            message.contact
          }}</span>
        </v-row>
      </v-col>
      <v-col v-if="message.guardian_info.length > 0" class="pt-0">
        <v-row
          no-gutters
          v-for="(guardian, index) in message.guardian_info"
          :key="index"
        >
          <img
            :class="
              classes.guardian.icon[
                guardian.message_delivery_status === 'success'
              ]
            "
            :src="smsOrEmailIcon"
          />
          <span
            class="mt-1 ml-1"
            :class="
              classes.guardian.text[
                guardian.message_delivery_status === 'success'
              ]
            "
            >{{ guardian["contact"] }}</span
          >
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MessageHistoryCard",
  props: {
    messageType: {
      required: true
    },
    message: {
      required: true
    },
    color: {
      required: true
    },
    student: {
      default: true
    },
    guardian: {
      default: false
    }
  },
  computed: {
    smsOrEmailIcon() {
      return this.messageType === "email"
        ? require("@ecf/global/assets/img/icons/email_icon.svg")
        : require("@ecf/global/assets/img/icons/sms_icon.svg");
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      return {
        student: {
          icon: !this.student
            ? "filter-grey"
            : this.message.message_delivery_status === "success"
            ? "filter-primary"
            : "filter-decline",
          text: !this.student
            ? "grey--text"
            : this.message.message_delivery_status === "success"
            ? "primary--text"
            : "decline--text"
        },
        guardian: {
          icon: {
            true: "filter-primary",
            false: "filter-decline"
          },
          text: {
            true: "primary--text",
            false: "decline--text"
          }
        }
      };
    }
  },
  methods: {
    getImageFromTitle(title) {
      let words = title.split(" ");
      return words
        .map(w => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    }
  }
};
</script>

<style scoped>
.filter-primary {
  filter: invert(38%) sepia(72%) saturate(1310%) hue-rotate(169deg)
    brightness(99%) contrast(101%);
}

.filter-orange {
  filter: invert(45%) sepia(74%) saturate(2367%) hue-rotate(346deg)
    brightness(100%) contrast(98%);
}

.filter-black {
  filter: invert(0%) sepia(4%) saturate(4%) hue-rotate(25deg) brightness(106%)
    contrast(100%);
}

.filter-grey {
  filter: invert(51%) sepia(1%) saturate(744%) hue-rotate(80deg) brightness(99%)
    contrast(96%);
}

.filter-decline {
  filter: invert(50%) sepia(75%) saturate(4839%) hue-rotate(336deg)
    brightness(100%) contrast(103%);
}

.inner-circle {
  width: 32px;
  height: 32px;
  padding-left: 10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  line-height: 30px;
  border-color: black;
  background-color: #eeeeee;
  color: black;
  font-size: 10px;
}
</style>
