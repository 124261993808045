// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".div-font-bg {\n  font-size: 16px;\n  padding-top: 0px;\n}\n.div-font-sm {\n  font-size: 14px;\n  padding-top: 0px;\n}\n.v-slide-group__wrapper {\n  display: flow-root;\n}\n@media screen and (max-width: 900px) {\n.container {\n    display: flex;\n    flex-flow: column-reverse;\n}\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
