var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      class: _vm.screenSize < 601 ? "mt-4 pl-3 pt-3" : "mt-4 pl-5 pt-3",
      style:
        _vm.screenSize < 1350
          ? "width: 100%; min-height: 90px"
          : "width: 86%; min-height: 90px",
      attrs: { flat: "", outlined: "" },
    },
    [
      _c(
        "v-row",
        { staticStyle: { height: "52%" }, attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-0 xl:text-xs lg:text-xs md:text-xs sm:text-xs",
              attrs: { cols: _vm.screenSize < 601 ? "12" : "5" },
            },
            [
              _c(
                "v-row",
                { staticClass: "ml-0", attrs: { "no-gutters": "" } },
                [
                  _c("v-avatar", { attrs: { color: "primary", size: "32" } }, [
                    _c("span", { staticClass: "white--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getImageFromTitle(_vm.message.student_name)
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "mt-2 ml-2" }, [
                    _vm._v(_vm._s(_vm.message.student_name)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              style:
                _vm.screenSize < 601
                  ? "padding-top: 8px; margin-left: 3px"
                  : "padding-top: 8px; margin-left: 6%",
            },
            [
              _c(
                "v-row",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../global/assets/img/icons/batch_icon.svg"),
                    },
                  }),
                  _c("div", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.message.batch_name)),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "pb-2 pt-2",
          staticStyle: { "font-size": "12px" },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            {
              style:
                _vm.screenSize < 601
                  ? "padding-top: 0px; max-width: 78%"
                  : "padding-top: 0px; max-width: 48%",
            },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _c("img", {
                  class: _vm.classes.student.icon,
                  attrs: { src: _vm.smsOrEmailIcon },
                }),
                _c(
                  "span",
                  { staticClass: "mt-1 ml-1", class: _vm.classes.student.text },
                  [_vm._v(_vm._s(_vm.message.contact))]
                ),
              ]),
            ],
            1
          ),
          _vm.message.guardian_info.length > 0
            ? _c(
                "v-col",
                { staticClass: "pt-0" },
                _vm._l(_vm.message.guardian_info, function (guardian, index) {
                  return _c(
                    "v-row",
                    { key: index, attrs: { "no-gutters": "" } },
                    [
                      _c("img", {
                        class:
                          _vm.classes.guardian.icon[
                            guardian.message_delivery_status === "success"
                          ],
                        attrs: { src: _vm.smsOrEmailIcon },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "mt-1 ml-1",
                          class:
                            _vm.classes.guardian.text[
                              guardian.message_delivery_status === "success"
                            ],
                        },
                        [_vm._v(_vm._s(guardian["contact"]))]
                      ),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }