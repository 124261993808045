var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-5" },
    [
      _vm.render_now
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.screenSize > 960 ? "" : "12" } },
                    [
                      _c("v-row", { staticStyle: { "max-height": "80px" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "mt-4 mb-6",
                            staticStyle: { "font-size": "25px" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: { color: "black", icon: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.renderNow = false
                                    _vm.$router.push("/teachers/sms-email")
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { size: "36" } }, [
                                  _vm._v("arrow_back"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(
                              " " + _vm._s(_vm.elementTypeCased) + " Details "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-card",
                            {
                              class:
                                _vm.screenSize < 601
                                  ? "pl-4 pt-2"
                                  : "pl-4 pt-2 ml-4",
                              attrs: {
                                width: _vm.screenSize < 601 ? "100%" : "90%",
                                flat: "",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticStyle: {
                                    "flex-direction": "row",
                                    "justify-content": "flex-start",
                                    "padding-top": "14px",
                                  },
                                  attrs: { "no-gutters": "" },
                                },
                                _vm._l(
                                  _vm.isSuccessful[3],
                                  function (courseName, i) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: i,
                                        staticClass: "ma-1",
                                        attrs: { color: "#D4D4D4", label: "" },
                                      },
                                      [_vm._v(_vm._s(courseName) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.screenSize < 601 ? "pl-0" : "pl-2",
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          class:
                                            _vm.screenSize > 601
                                              ? "ml-3 mt-0"
                                              : "mt-3 ml-3",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                new Date(
                                                  _vm.dateTimeFormat
                                                ).toDateString()
                                              )
                                            ),
                                          ]),
                                          _c("div", { staticClass: "ml-4" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  new Date(
                                                    _vm.dateTimeFormat
                                                  ).toLocaleString("en-US", {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                  })
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: {
                                        "padding-top": "0px",
                                        "margin-left": "3%",
                                      },
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticStyle: {
                                              "pointer-events": "none",
                                            },
                                            attrs: { color: "b4b4b4" },
                                            model: {
                                              value:
                                                _vm.detailsResponse
                                                  .is_send_to_student,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailsResponse,
                                                  "is_send_to_student",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailsResponse.is_send_to_student",
                                            },
                                          }),
                                          _c("div", { staticClass: "mt-5" }, [
                                            _vm._v("Students"),
                                          ]),
                                          _c("v-checkbox", {
                                            staticClass: "ml-4",
                                            staticStyle: {
                                              "pointer-events": "none",
                                            },
                                            attrs: { color: "b4b4b4" },
                                            model: {
                                              value:
                                                _vm.detailsResponse
                                                  .is_send_to_guardian,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailsResponse,
                                                  "is_send_to_guardian",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailsResponse.is_send_to_guardian",
                                            },
                                          }),
                                          _c("div", { staticClass: "mt-5" }, [
                                            _vm._v("Guardians"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.screenSize > 1279
                                          ? "div-font-bg"
                                          : "div-font-sm",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          class:
                                            _vm.screenSize < 601
                                              ? "ml-0"
                                              : "ml-3",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Total Sent " +
                                                _vm._s(_vm.elementTypeCased) +
                                                ":"
                                            ),
                                          ]),
                                          _c("div", { staticClass: "ml-2" }, [
                                            _vm._v(_vm._s(_vm.sentTo.length)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.screenSize > 1279
                                          ? "div-font-bg"
                                          : "div-font-sm",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          class:
                                            _vm.screenSize < 601
                                              ? "ml-0"
                                              : "ml-3",
                                        },
                                        [
                                          _c("div", [_vm._v("Batch(s):")]),
                                          _c("div", { staticClass: "ml-2" }, [
                                            _vm._v(_vm._s(_vm.isSuccessful[2])),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.screenSize > 1279
                                          ? "div-font-bg"
                                          : "div-font-sm",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          class:
                                            _vm.screenSize < 601
                                              ? "ml-0"
                                              : "ml-3",
                                          staticStyle: { color: "#0099dc" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Successful " +
                                                _vm._s(_vm.elementTypeCased) +
                                                ":"
                                            ),
                                          ]),
                                          _c("div", { staticClass: "ml-2" }, [
                                            _vm._v(_vm._s(_vm.isSuccessful[0])),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.screenSize > 1279
                                          ? "div-font-bg"
                                          : "div-font-sm",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "decline--text",
                                          class:
                                            _vm.screenSize < 601
                                              ? "ml-0"
                                              : "ml-3",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Failed " +
                                                _vm._s(_vm.elementTypeCased) +
                                                ":"
                                            ),
                                          ]),
                                          _c("div", { staticClass: "ml-2" }, [
                                            _vm._v(_vm._s(_vm.isSuccessful[1])),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.elementType === "email"
                        ? _c("div", { staticStyle: { "margin-top": "6%" } }, [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "18px" } },
                              [_vm._v("Subject")]
                            ),
                            _c("div", {
                              staticClass:
                                "bg-white pa-6 mt-4 border-1-2 border-solid border-default rounded xl:text-xs lg:text-xs md:text-xs sm:text-xs",
                              staticStyle: { width: "95%" },
                              domProps: {
                                innerHTML: _vm._s(_vm.detailsResponse.subject),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c("div", { staticStyle: { "margin-top": "6%" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "500",
                            },
                          },
                          [_vm._v("Message")]
                        ),
                        _c("div", {
                          staticClass:
                            "bg-white pa-6 mt-4 border-1-2 border-solid border-default rounded xl:text-xs lg:text-xs md:text-xs sm:text-xs",
                          staticStyle: { width: "95%" },
                          domProps: {
                            innerHTML: _vm._s(_vm.detailsResponse.message_body),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tabs",
                        {
                          staticClass: "pb-4",
                          staticStyle: { display: "flow-root" },
                          attrs: {
                            "hide-slider": "",
                            "background-color": "#f3f7ff",
                            color: "#f3f7ff",
                          },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            { staticStyle: { "background-color": "#f3f7ff" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.elementTypeCased) + " log "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          staticStyle: { "max-height": "100%" },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              staticStyle: {
                                "background-color": "#f3f7ff",
                                "max-height": "80vh",
                                width: "100%",
                                "overflow-y": "scroll",
                              },
                            },
                            _vm._l(_vm.sentTo, function (sent, i) {
                              return _c(
                                "div",
                                { key: i },
                                [
                                  _c("message-history-card", {
                                    attrs: {
                                      "message-type": _vm.elementType,
                                      message: sent,
                                      student:
                                        _vm.detailsResponse.is_send_to_student,
                                      guardian:
                                        _vm.detailsResponse.is_send_to_guardian,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }